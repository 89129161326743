
import {computed, defineComponent} from "vue";
import {useLoadingStore} from "@/store/loading";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";

export default defineComponent({
  name: "Entities",
  components: {EntityLoading},
  props: {
    displayView: {type: Boolean, default: true},
    showLabel: {type: Boolean, default: true},
    label: {type: String, default: 'Items'},
    items: {type: Number, default: 0},
    viewCard: {type: Boolean, default: false},
    viewTable: {type: Boolean, default: false},
    enableFilter: {type: Boolean, default: false}
  },
  setup() {
    const store = useLoadingStore();
    const loading = computed(() => store.entitiesLoading)
    return {
      loading
    }
  }
})
